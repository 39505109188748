<template>
  <div class="grid row">
    <div class="flex xs12 md12">
      <va-card title="Edit Demo Users">
        <div class="row align--center">
          <div class="flex xs12 md3">
            <va-input
              v-model="term"
              :placeholder="'Search by name or email'"
              removable
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex xs12 md3">
            <va-select
              label="Filters"
              v-model="filterModel"
              text-by="label"
              key-by="value"
              :options="filterOptions"
            />
          </div>
          <div class="flex xs12 md3 offset--md3">
            <va-select
              v-model="perPage"
              :label="$t('tables.perPage')"
              :options="perPageOptions"
              no-clear
            />
          </div>
        </div>
        <div class="row">
          <va-data-table
            class="flex xs12 mb-2"
            :fields="fields"
            :loading="TableLoading"
            :data="filteredUsers"
            :per-page="parseInt(perPage)"
            clickable
            hoverable
          >
            <!-- Avatar IMG -->
            <template slot="avatar" slot-scope="props">
              <va-item-section avatar>
                <va-avatar>
                  <img
                    :src="props.rowData.photoURL"
                    :alt="props.rowData.displayName"
                  />
                </va-avatar>
              </va-item-section>
            </template>
            <template slot="name" slot-scope="props">
              {{ props.rowData.displayName }}
              <va-item-label caption>
                {{ props.rowData.email }}
              </va-item-label>
            </template>
            <!-- /end -  Avatar IMG -->
            <!-- /start - demoValidUntil -->
            <template slot="demoValidUntil" slot-scope="props">
              {{
                props.rowData.demoValidUntil
                  ? $moment(
                      props?.rowData?.demoValidUntil?.seconds * 1000
                    ).format("MM/DD/YYYY HH:mm:ss")
                  : "N/A"
              }}
            </template>
            <template slot="remainingDemoDays" slot-scope="props">
              {{ props.rowData.remainingDemoDays }} Days
            </template>
            <template slot="actions" slot-scope="props">
              <va-button medium @click="gotoUser(props.rowData.uid)">
                Show User
              </va-button>
              <va-button medium @click="extendTrial(props.rowData)">
                Extend Trial Days
              </va-button>
              <va-button medium @click="reduceTrial(props.rowData)">
                Reduce Trial Days
              </va-button>
            </template>
            <!-- /end - lastLogin -->
          </va-data-table>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import axios from "@/scripts/interceptor.js";
import { SemipolarSpinner } from "epic-spinners";
import firebase from "firebase";
export default {
  name: "Admin",
  data() {
    return {
      email: "",
      message: "f",
      term: "",
      perPage: "10",
      filterModel: "",
      filterModel: "",
      filterOptions: [],
      perPageOptions: ["1", "5", "10"],
      showModal: true,
      loading: false,
      active: true,
      showPermissionsModal: false,
      authLoading: false,
      TableLoading: true,
      admins: [],
      selected: [],
    };
  },
  components: {
    SemipolarSpinner,
  },
  computed: {
    fields() {
      return [
        {
          name: "__slot:avatar",
          width: "30px",
          height: "45px",
          dataClass: "text-center",
        },
        {
          name: "__slot:name",
          title: this.$t("tables.headings.name"),
          width: "10%",
        },
        {
          name: "demoStartedAt",
          title: "Start Date",
          width: "20%",
        },
        {
          name: "__slot:remainingDemoDays",
          title: "Remaining Days",
          width: "10%",
        },
        {
          name: "__slot:demoValidUntil",
          width: "20%",
          title: "Valid Until",
        },
        {
          name: "__slot:actions",
          title: this.$t("tables.headings.name"),
          width: "70%",
        },
      ];
    },
    filteredUsers() {
      //order by created date
      this.admins.sort((a, b) => {
        if (a.creationDate < b.creationDate) {
          return -1;
        }
        if (a.creationDate > b.creationDate) {
          return 1;
        }
        return 0;
      });

      if (!this.term || this.term.length < 1) {
        return this.admins;
      }
      return this.admins.filter((user) => {
        const searchTerm = this.term.toLowerCase();
        return (
          user.displayName.toLowerCase().includes(searchTerm) ||
          user.email.toLowerCase().includes(searchTerm)
        );
      });
    },
  },
  watch: {},
  methods: {
    ok() {
      this.showModal = false;
    },
    extendDays(user) {
      this.loading = false;
    },
    async extendTrial(user) {
      const { value: days } = await this.$swal({
        title: "Extend Trial Period",
        text: `Extend trial period for ${user.displayName}`,
        input: "number",
        inputLabel: "Number of days (1-7)",
        inputAttributes: {
          min: "1",
          max: "7",
          step: "1",
        },
        showCancelButton: true,
        inputValidator: (value) => {
          const num = parseInt(value);
          if (!value) {
            return "Please enter number of days";
          }
          if (num < 1 || num > 7) {
            return "Days must be between 1 and 7";
          }
        },
      });
    
      if (days) {
        try {
          const newDate = new Date();
          if (user.demoValidUntil) {
            newDate.setTime(user?.demoValidUntil?.seconds * 1000);
          }
          // Add days
          newDate.setDate(newDate.getDate() + parseInt(days));
    
          const updateData = {
            demoValidUntil: firebase.firestore.Timestamp.fromDate(newDate),
          };
    
          // Check if demoStartedAt is not set
          if (!user.demoStartedAt || user.demoStartedAt == "Not Found") {
            updateData.demoStartedAt = firebase.firestore.Timestamp.fromDate(new Date());
            user.demoStartedAt = this.$moment(new Date()).format("MM/DD/YYYY HH:mm:ss");
          }

          if (!user.firstDemoExtentedAt || user.firstDemoExtentedAt == "Not Found") {
              //create date from demoValidUntil
              const demoValidUntil = new Date();
              demoValidUntil.setTime(user?.demoValidUntil?.seconds * 1000);
              demoValidUntil.setHours(15);
              updateData.firstDemoExtentedAt = firebase.firestore.Timestamp.fromDate(demoValidUntil);
              user.firstDemoExtentedAt = this.$moment(demoValidUntil).format("MM/DD/YYYY HH:mm:ss");
          }

          //check if firstDemoExtentedAt + 7 days is greater than newDate
          if (user.firstDemoExtentedAt) {
            const firstDemoExtentedAt = new Date();
            const defaultFirstDemoExtentedAt = new Date();
            defaultFirstDemoExtentedAt.setTime(user?.firstDemoExtentedAt?.seconds * 1000);
            firstDemoExtentedAt.setTime(user?.firstDemoExtentedAt?.seconds * 1000);
            firstDemoExtentedAt.setDate(firstDemoExtentedAt.getDate() + 7);
            console.log({
              firstDemoExtentedAt: firstDemoExtentedAt,
              newDate: newDate,
            });
            if (firstDemoExtentedAt <= newDate) {
              this.$swal({
                icon: "warning",
                title: "Cannot Extend Trial",
                text: "The trial period can only be extended for 7 days beyond the days stipulated when creating the demo account. The initial expiration date was " + this.$moment(defaultFirstDemoExtentedAt).format("MM/DD/YYYY") + " and the maximum extension date is " + this.$moment(firstDemoExtentedAt).format("MM/DD/YYYY") + " (MM/DD/YYYY).",
              });
              return;
            }
          }
   

    
          await firebase
            .firestore()
            .collection("users")
            .doc(user.uid)
            .update(updateData);
    
          this.$swal({
            icon: "success",
            title: "Trial Extended!",
            text: `Successfully extended trial for ${user.displayName} by ${days} days`,
          });
          
          user.remainingDemoDays = Math.ceil(this.$moment(newDate).diff(
              this.$moment(),
              "days",
              true
          ))
          // Update table
          await this.refreshTable();
        } catch (error) {
          this.$swal({
            icon: "error",
            title: "Error",
            text: "Failed to extend trial period",
          });
          console.error("Error extending trial:", error);
        }
      }
    },
       async reduceTrial(user) {
      const { value: days } = await this.$swal({
        title: "Reduce Trial Period",
        text: `Reduce trial period for ${user.displayName}`,
        input: "number",
        inputLabel: "Number of days (1-30)",
        inputAttributes: {
          min: "1",
          max: "30",
          step: "1",
        },
        showCancelButton: true,
        inputValidator: (value) => {
          const num = parseInt(value);
          if (!value) {
            return "Please enter number of days";
          }
          if (num < 1 || num > 30) {
            return "Days must be between 1 and 30";
          }
        },
      });
    
      if (days) {
        try {
          const newDate = new Date();
          // Se já existe uma data válida, usa ela como base
          if (user.demoValidUntil) {
            newDate.setTime(user?.demoValidUntil?.seconds * 1000);
          }
          // Subtrai os dias
          newDate.setDate(newDate.getDate() - parseInt(days));
          
   
          const futureDate = new Date();
          futureDate.setDate(futureDate.getDate() - 1);
          console.log({
            newDate: newDate,
            newDate2: futureDate,
          });
          // Verifica se a nova data é anterior à data atual
          if (newDate <= futureDate) {
            this.$swal({
              icon: "error",
              title: "Invalid Date",
              text: "The reduced trial period cannot be set to a date earlier than today.",
            });
            return;
          }
    
          await firebase
            .firestore()
            .collection("users")
            .doc(user.uid)
            .update({
              demoValidUntil: firebase.firestore.Timestamp.fromDate(newDate),
            });
    
          this.$swal({
            icon: "success",
            title: "Trial Reduced!",
            text: `Successfully reduced trial for ${user.displayName} by ${days} days`,
          });
    
          // Atualiza a tabela
          await this.refreshTable();
        } catch (error) {
          this.$swal({
            icon: "error",
            title: "Error",
            text: "Failed to reduce trial period",
          });
          console.error("Error reducing trial:", error);
        }
      }
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    gotoUser(uid) {
      if (this.active) {
        this.$router.push({ path: "edituser/" + uid });
      } else {
        this.active = true;
      }
    },
    async refreshTable() {
      this.TableLoading = true;
      this.admins = [];

      //get from users collection users that have demoAccess true
      await firebase
        .firestore()
        .collection("users")
        .where("demoAccess", "==", true)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            //if photo.URL is null or undefined, set it to default
            let currentData = doc.data();
            //append key 'uid'
            currentData.uid = doc.id;
            if (!currentData.photoURL) {
              currentData.photoURL =
                "https://nihss.plus/assets/defaultProfilePic.png";
            }
            currentData.demoStartedAt = currentData?.demoStartedAt?.seconds
              ? this.$moment(currentData?.demoStartedAt?.seconds * 1000).format(
                  "MM/DD/YYYY HH:mm:ss"
                )
              : "Not Found";

              currentData.remainingDemoDays = currentData.demoValidUntil
              ? Math.ceil(this.$moment(currentData?.demoValidUntil?.seconds * 1000).diff(
                  this.$moment(),
                  "days",
                  true
                ))
              : "0";

            if (currentData.remainingDemoDays < 0) {
              currentData.remainingDemoDays = 0;
            }

            this.admins.push(currentData);
            this.TableLoading = false;
          });
          //sort this.admins by displayName
          this.admins.sort((a, b) => {
            if (a.displayName < b.displayName) {
              return -1;
            }
            if (a.displayName > b.displayName) {
              return 1;
            }
            return 0;
          });
          this.loading = false;
        });

      if (this.admins.length < 1) {
        this.TableLoading = false;
      }
    },
  },
  async mounted() {
    await this.refreshTable();
  },
};
</script>

<style lang="scss">
.btn-container button[disabled] {
  cursor: not-allowed;
}
</style>
